import React from 'react';
import { Link } from 'react-router-dom';
import { useGeneralData } from "../../../data/GeneralDataContext";

const Footer = () => {
    const { evd_general_data, isLoading, error } = useGeneralData();

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!evd_general_data || evd_general_data.length === 0) return <div>No General data available</div>;

    return (
        <>
            <footer className="small-footer">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <p className="copyright mb-0">
                                © {new Date().getFullYear()} EVD Construction. All Rights Reserved.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 text-end">
                            <Link to="/terms" className="terms-link">
                                Terms & Conditions
                            </Link>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
