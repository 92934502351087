import React from 'react';
import '../assets/css/style.css';

const TermsAndConditions = () => {
        return (
            <>
                    <div className="header_layer_bg"></div>
                    <div className="main_wrapper">
                            <section className="terms_page">
                                    <div className="container">
                                            <div className="row justify-content-center">
                                                    <div className="col-lg-10">
                                                            <div className="terms-content text-center mb-5">
                                                                    <h1>Terms and Conditions</h1>
                                                                    <p className="last-updated">Last updated: November 1, 2024</p>
                                                            </div>

                                                            <div className="terms-sections">
                                                                    <div className="terms-section">
                                                                            <p>Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using EVDWEB (the "Service") operated by Evolution Design Inc ("us", "we", or "our").</p>
                                                                            <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service.</p>
                                                                            <p>By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service.</p>
                                                                    </div>

                                                                    <div className="terms-section">
                                                                            <h2>1. Content Ownership and Copyright</h2>
                                                                            <p>1.1 All content on this website, including but not limited to text, graphics, logos, images, audio clips, digital downloads, data compilations, and software, is the property of Evolution Design Inc or its content suppliers and is protected by international copyright laws.</p>
                                                                            <p>1.2 The compilation of all content on this site is the exclusive property of Evolution Design Inc and is protected by international copyright laws.</p>
                                                                            <p>1.3 All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website.</p>
                                                                    </div>



                                                                    <div className="terms-section">
                                                                            <h2>4. Limitation of Liability</h2>
                                                                            <p>In no event shall Evolution Design Inc, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.</p>
                                                                    </div>

                                                                    <div className="terms-section">
                                                                            <h2>5. Changes</h2>
                                                                            <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect.</p>
                                                                    </div>
                                                            </div>
                                                    </div>
                                            </div>
                                    </div>
                            </section>
                    </div>
            </>
        );
};

export default TermsAndConditions;
