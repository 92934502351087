import React from 'react';
import MobileResponsiveMenu from '../MobileResponsiveMenu';
import UpperHeader from './UpperHeader';

const Header = ({ toggleMenu, isMobile, menuVisible }) => {
    return (
        <div className='header-container'>
            <UpperHeader />
            <MobileResponsiveMenu
            />
        </div>
    );
};

export default Header;