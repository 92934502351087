import React from 'react';
import {useGeneralData} from "../data/GeneralDataContext";

const MissionContainer = () => {
    const { evd_general_data, isLoading, error } = useGeneralData();


    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!evd_general_data || evd_general_data.length === 0) return <div>No General data available</div>;
    //console.log(projectData)
    return (
        <section className="mission">
            <div className="container">
                <div className="mission_top_part">
                    <div className="section-header text-center">
                        <h6 className="text-white text-uppercase">{evd_general_data[0].about_mission}</h6>
                        <p className="text-gray-600">{evd_general_data[0].mission_statement_text}</p>
                    </div>
                    <div className="has_line"></div>
                    <img src="images/bg/ed-mission.png" alt="" />
                </div>

               <div className="mission_bottom_part">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 pe-lg-4">
                            <div className="section-header">
                                <h3 className="text-white text-uppercase border-line">{evd_general_data[0].about_header}</h3>
                            </div>
                        </div>
                        <div className="col-lg-6 ps-lg-4">
                            <div className="mission_content">
                                <h6>{evd_general_data[0].about_us_1_header}</h6>
                                <p>{evd_general_data[0].about_us_1_text}</p>
                                <h6>{evd_general_data[0].about_us_2_header}</h6>
                                <p>{evd_general_data[0].about_us_2_text}</p>
                                <h6>{evd_general_data[0].about_us_3_header}</h6>
                                <p>{evd_general_data[0].about_us_3_text}</p>
                                <h6>{evd_general_data[0].about_us_4_header}</h6>
                                <p>{evd_general_data[0].about_us_4_text}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MissionContainer;