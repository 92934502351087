import Isotope from 'isotope-layout';
import React, { useEffect, useState } from 'react';
import PageHeder from '../../components/PageHeder';
import Paragraph from '../../components/Paragraph';
import ProjectCard from '../../components/ProjectElements/ProjectCard';
import SectionGridLines from '../../components/SectionGridLines';
import SectionHeader from '../../components/SectionHeader';
import { useProjectData } from '../../data/ProjectDataContext';
import imagesLoaded from 'imagesloaded';
import PageBanner from "../PageBanner"; // Add this import

const ProjectGallery = () => {
    console.log("In Project Gallery")
    const {projectData, isLoading, error} = useProjectData();
    const [filterKey, setFilterKey] = useState('*');
    const [isotope, setIsotope] = useState(null);
    // Add this useEffect to your component to prevent body scrolling
    useEffect(() => {
        // Prevent scrolling on the body
        document.body.style.overflow = 'hidden';

        // Cleanup when component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);
    useEffect(() => {
        if (projectData && !isLoading) {
            const grid = document.querySelector('.grid');

            // Wait for images to load before initializing Isotope
            imagesLoaded(grid, () => {
                const iso = new Isotope('.grid', {
                    itemSelector: '.grid-item',
                    percentPosition: true,
                    transformsEnabled: true,
                    transitionDuration: "700ms",
                    resize: true,
                    fitWidth: true,
                });
                setIsotope(iso);
            });
        }
    }, [projectData, isLoading]);

    useEffect(() => {
        if (isotope) {
            // Add a small delay to ensure DOM updates are complete
            setTimeout(() => {
                filterKey === '*'
                    ? isotope.arrange({ filter: `*` })
                    : isotope.arrange({ filter: `.${filterKey}`});

                // Force layout update
                isotope.layout();
            }, 700);
        }
    }, [isotope, filterKey]);

    const handleFilterKeyChange = (key) => () => {
        setFilterKey(key);
    };

    // Add window resize handler
    useEffect(() => {
        const handleResize = () => {
            if (isotope) {
                isotope.layout();
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isotope]);

    // Add CSS to your component or in your stylesheet
    const gridStyles = {
        grid: {
            width: '100%',
            position: 'relative',
        },
        gridItem: {
            float: 'left',
            width: 'calc(33.333% - 20px)', // Adjust based on your needs
            margin: '10px',
        }
    };
    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!projectData) {
        return <div>No project data available</div>;
    }

    return (
        <section className="projects packery">

            <div className="fixed-header-section">
            <SectionGridLines></SectionGridLines>
                <PageBanner pageTitle='Pages' title="Gallery" ></PageBanner>
            {/* Filter Header */}
            <div className="filter-section">
                <div className="container">
                    <div className="portfolio-filters-content">
                        <div className="filters-button-group">
                            <button className="button" onClick={handleFilterKeyChange('*')}>All <sup
                                className="filter-count"></sup></button>
                            <button className="button" onClick={handleFilterKeyChange('workplace-design')}>Workplace
                                Design <sup className="filter-count"></sup></button>
                            <button className="button" onClick={handleFilterKeyChange('industrial')}>Industrial <sup
                                className="filter-count"></sup></button>
                            <button className="button" onClick={handleFilterKeyChange('restaurant')}>Restaurant <sup
                                className="filter-count"></sup></button>
                            <button className="button" onClick={handleFilterKeyChange('retail')}>Retail <sup
                                className="filter-count"></sup></button>
                        </div>
                    </div>
                </div>
            </div>
            </div>


            {/* Grid Container */}
            <div className="scrollable-grid-container">
                <div className="container">
                    <div className="grid gutter-20 clearfix">
                        <div className="grid-sizer"></div>
                        {projectData && !isLoading && projectData
                            .filter(data => data.isInGallery === 'yes')
                            .map(data =>
                                <ProjectCard data={data}/>
                            )}
                    </div>
                </div>
            </div>
        </section>
);
}


export default ProjectGallery;