import React, { useState, useEffect, useCallback,useRef } from 'react';
import { Outlet,useLocation } from 'react-router-dom';
import Footer from '../Pages/Shared/Footers/Small-Footer';
import Header from '../Pages/Shared/Headers/Header';
import SectionGridLines from '../components/SectionGridLines';

const HomeLayout = () => {
    const location = useLocation();
    const [mobileMenu, setMobileMenu] = useState(false);
    const [menuVisible, setMenuVisible] = useState(true);
    const menuRef = useRef(null);
    // Add paths where you don't want the footer to appear
    const noFooterPaths = [

        // add other paths as needed
    ];

    const shouldShowFooter = !noFooterPaths.includes(location.pathname);

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.matchMedia("(max-width: 800px)").matches;
            setMobileMenu(isMobile);
            setMenuVisible(!isMobile);
        };

        // Initial check
        handleResize();

        // Debounced resize handler
        let resizeTimer;
        const debouncedResize = () => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(handleResize, 100);
        };

        window.addEventListener('resize', debouncedResize);

        return () => {
            window.removeEventListener('resize', debouncedResize);
            clearTimeout(resizeTimer);
        };
    }, []);

    const toggleMenu = useCallback(() => {
        setMenuVisible(prev => !prev);
    }, []);

    return (
        <>
            <Header
                ref={menuRef}
                toggleMenu={toggleMenu}
                isMobile={mobileMenu}
                menuVisible={menuVisible}
            />
            <Outlet />
            <SectionGridLines />
            {shouldShowFooter && <Footer />}
        </>
    );
};

export default HomeLayout;