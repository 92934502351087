import { createBrowserRouter } from "react-router-dom";
import AuthGuard from '../components/AuthGuard';  // Adjust path as needed
import Dashboard from '../components/Dashboard';   // Adjust path as needed
import Login from '../components/Login';
import HomeLayout from "../Layouts/HomeLayout";
import AboutUs from "../Pages/AboutUs";
import Leadership from "../Pages/TeamPages/Leadership";
import LeaderDetails from "../Pages/TeamPages/LeaderDetails";
import Contact from "../Pages/Contact";
import ErrorPage from "../Pages/ErrorPage";
import Home01 from "../Pages/HomePages/Home01";
import ProjectDetails from "../Pages/ProjectPages/ProjectDetails";
import ProjectGallery from "../containers/ProjectContainers/ProjectGallery";
import TermsAndConditions from "../Pages/Terms";


export const routes = createBrowserRouter([
    {
        path: "/",
        element: <HomeLayout />,
        children: [

            {
                path: '/projects',
                element: <ProjectGallery/>,
            },
            {
                path: '/project-details',
                element: <ProjectDetails />,
            },
            {
               path: '/leadership',
               element: <Leadership />,
            },
            {
                path: '/leader-details',
                element: <LeaderDetails />,
            },
            {
                path: '/contact',
                element: <Contact />,
            },

            {
                path: '/about',
                element: <AboutUs />,
            },
            {
                path: '/login',
                element: <Login />,
            },
            // Add protected dashboard route
            {
                path: '/dashboard',
                element: (
                    <AuthGuard>
                        <Dashboard />
                    </AuthGuard>
                ),
            },
            {
                path: '/',
                element: <Home01/>,
            },
            {
                path: '/terms',
                element: <TermsAndConditions/>,
            },

            {
                path: '*',
                element: <ErrorPage />,
            },

        ]
    }
])