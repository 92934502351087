
import '../assets/css/style.css'


const SectionHeader = (props) => {
    return (
        <h2 className="section-header">
            {props.title}
        </h2>
    );
};

export default SectionHeader