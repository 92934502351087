import React from 'react';
import PageBanner from '../../containers/PageBanner';
import LeaderDetailsContainer from '../../containers/TeamContainers/LeaderDetailsContainer';
import {useLocation} from "react-router-dom";


const reviverFunction = (key, value) => {
    if (key === null)
        return undefined;
    if (value === null) {
        // Handle or filter out null values here
        return undefined; // Return undefined to remove null values from the parsed object
    }
    return value;
};

const LeaderDetails = () => {
    let location = useLocation();
    let data = JSON.parse(location.state, reviverFunction);


    return (
        <>
            <PageBanner pageTitle='Pages' title="Details" activePage="Leadership" />
                <LeaderDetailsContainer data_state={data}/>

        </>
    );
};

export default LeaderDetails;