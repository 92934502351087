import React from 'react';
import MissionContainer from "../containers/MissionContainer";
import TextAbout from './TextAbout';
import { TeamData } from '../data/team_data'

const AboutUs = () => {


    return (
        <main className="wrapper">
            <MissionContainer />

        </main>
    );
};

export default AboutUs;