import $ from 'jquery';
import React, {useEffect, useState} from 'react';
import { getProjectData } from '../../data/project_data'
const Loader = (props) => {
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        const hasLoadedBefore = sessionStorage.getItem('loaderLoaded');

        if (!hasLoadedBefore) {
            // If not, set the loader as loaded and update the session storage
            setIsLoaded(true);
            sessionStorage.setItem('loaderLoaded', 'true');
        }
        // Preloader
        setTimeout(function () {
            const preloader = $('#preloader');
            preloader.fadeOut(400, function() {
                preloader.remove(); // This will completely remove the element from DOM
            });
        }, 6500);
    }, []);

    if (!isLoaded) {
        return null; // Don't render the loader if it has been loaded before
    }
    return (
        <div id="preloader" style={{ backgroundColor: 'white', height: '100vh' ,
            position: 'fixed', // Ensure it covers the whole viewport
            width: '100%',
            top: 0,
            left: 0,
            zIndex: 9999 // Make sure it's on top of everything
        }}>
            <div className="preloader-inner">

                <div className="loading-text">
                    <span data-preloader-text="E" className="characters">E</span>

                    <span data-preloader-text="V" className="characters">V</span>

                    <span data-preloader-text="O" className="characters">O</span>

                    <span data-preloader-text="L" className="characters">L</span>

                    <span data-preloader-text="U" className="characters">U</span>

                    <span data-preloader-text="T" className="characters">T</span>

                    <span data-preloader-text="I" className="characters">I</span>

                    <span data-preloader-text="O" className="characters">O</span>

                    <span data-preloader-text="N" className="characters">N</span>

                    <br></br>

                    <span data-preloader-text="D" className="characters">D</span>

                    <span data-preloader-text="E" className="characters">E</span>

                    <span data-preloader-text="S" className="characters">S</span>

                    <span data-preloader-text="I" className="characters">I</span>

                    <span data-preloader-text="G" className="characters">G</span>

                    <span data-preloader-text="N" className="characters">N</span>

                </div>
            </div>
        </div>

    );
};

export default Loader;
