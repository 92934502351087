import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const MobileResponsiveMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 991);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    useEffect(() => {
        if (isOpen) {
            document.body.style.transform = 'translateX(-280px)';
            document.body.style.transition = 'transform 0.3s ease-in-out';
        } else {
            document.body.style.transform = 'translateX(0)';
        }
        return () => {
            document.body.style.transform = 'translateX(0)';
            document.body.style.transition = '';
        };
    }, [isOpen]);

    const menuItems = [
        { text: 'HOME', to: '/' },
        { text: 'GALLERY', to: '/projects' },
        { text: 'ABOUT', to: '/about' },
        { text: 'CONTACT', to: '/contact' }
    ];

    const linkStyle = {
        color: '#ffffff',
        textDecoration: 'none',
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '2px',
        display: 'block',
        padding: '15px 0',
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        fontFamily: '"Roboto", sans-serif',
        backgroundColor: '#000000',
        textAlign: 'right',
        transition: 'color 0.3s ease'
    };

    const [hoveredIndex, setHoveredIndex] = useState(null);

    return (
        <>
            {isMobile && (
                <>
                    <button
                        onClick={() => setIsOpen(true)}
                        style={{
                            position: 'fixed',
                            top: '20px',
                            right: '20px',
                            padding: '10px',
                            background: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            zIndex: 1000,
                            fontSize: '24px',
                            color: '#393836',
                            transform: isOpen ? 'translateX(-280px)' : 'translateX(0)',
                            transition: 'transform 0.5s ease-in-out'
                        }}
                    >
                        ☰
                    </button>

                    <div style={{
                        position: 'fixed',
                        top: 0,
                        right: 0,
                        width: '280px',
                        height: 'auto',
                        minHeight:'min-content',
                        backgroundColor: '#000000',
                        zIndex: 1002,
                        transform: isOpen ? 'translateX(0)' : 'translateX(280px)',
                        transition: 'transform 0.5s ease-in-out'
                    }}>
                        <nav style={{
                            backgroundColor: '#000000',
                            width: '100%',
                            height:'auto'
                        }}>
                            <ul style={{
                                listStyle: 'none',
                                padding: '0 20px',
                                margin: '40px 0 15px 0',
                                backgroundColor: '#000000',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end'
                            }}>
                                {menuItems.map((item, index) => (
                                    <li key={index} style={{
                                        backgroundColor: '#000000',
                                        width: '100%',
                                        textAlign: 'right'
                                    }}>
                                        <Link
                                            to={item.to}
                                            style={{
                                                ...linkStyle,
                                                color: hoveredIndex === index ? '#808080' : '#ffffff'
                                            }}
                                            onMouseEnter={() => setHoveredIndex(index)}
                                            onMouseLeave={() => setHoveredIndex(null)}
                                            onClick={() => setIsOpen(false)}
                                        >
                                            {item.text}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>

                    {isOpen && (
                        <div
                            onClick={() => setIsOpen(false)}
                            style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                zIndex: 1001,
                                transform: 'translateX(-280px)',
                                transition: 'transform 0.3s ease-in-out'
                            }}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default MobileResponsiveMenu;
