import React from 'react';
import PageBanner from '../../containers/PageBanner';
import LeadershipContainer from '../../containers/TeamContainers/LeadershipContainer';

const Leadership = () => {
    return (
        <>
            <PageBanner pageTitle='Pages' title="Our Leadership" activePage="Leadership" />
            <main className="wrapper">
                <LeadershipContainer />
            </main>
        </>
    );
};

export default Leadership;