import React from 'react';
import { useAuth } from "react-oidc-context";

function Login() {
    const auth = useAuth();

    // Add console log to check if auth is properly initialized
    React.useEffect(() => {
        console.log('Auth context:', auth);
    }, [auth]);

    const handleSocialLogin = async (provider) => {
        try {
            console.log(`${provider} login button clicked`); // Debug click

            // Check if auth is available
            if (!auth) {
                console.error('Auth context is not available');
                return;
            }

            // Check if signinRedirect is available
            if (!auth.signinRedirect) {
                console.error('signinRedirect method is not available');
                return;
            }

            // Attempt login with more specific configuration
            await auth.signinRedirect({
                redirect_uri: window.location.origin,
                extraQueryParams: {
                    identity_provider: provider,
                    client_id: "6ss3c3s2o94ct39vejafgt8pc9",
                }
            });

        } catch (error) {
            console.error(`${provider} login error:`, error);
        }
    };

    // Add loading state handling
    if (auth?.isLoading) {
        return <div>Loading...</div>;
    }

    // Add error state handling
    if (auth?.error) {
        return <div>Authentication Error: {auth.error.message}</div>;
    }

    return (
        <div className="login-page">
            <div className="login-box">
                <div className="login-header">
                    <h2>Welcome Back</h2>
                    <p>Please sign in with your social account</p>
                </div>

                <div className="login-form">
                    <button
                        onClick={() => handleSocialLogin('Google')}
                        className="login-button google"
                        type="button"
                        style={{ marginBottom: '10px' }} // Add some spacing between buttons
                    >
                        Sign in with Google
                    </button>
                    <button
                        onClick={() => handleSocialLogin('Apple')}
                        className="login-button apple"
                        type="button"
                        style={{ marginBottom: '10px' }}
                    >
                        Sign in with Apple
                    </button>
                    <button
                        onClick={() => handleSocialLogin('Facebook')}
                        className="login-button facebook"
                        type="button"
                    >
                        Sign in with Facebook
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Login;
