import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import './assets/css/style.css';
import './assets/css/login.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "react-oidc-context";

const dev_mode = true

const cognitoAuthConfig = {
    authority: "https://cognito-idp.us-west-2.amazonaws.com/us-west-2_jajj6lkIQ",
    client_id: "6ss3c3s2o94ct39vejafgt8pc9",
    redirect_uri: window.location.origin,
    response_type: "code",
    scope: "phone openid email profile",
    onSigninCallback: () => {
        window.history.replaceState({}, document.title, window.location.pathname);
    },
    loadUserInfo: true,
    onSigninError: (error) => {
        console.error('Signin error:', error);
    }
};


const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
        <React.StrictMode>
            <AuthProvider {...cognitoAuthConfig}>
            <App/>
                </AuthProvider>
        </React.StrictMode>
    );


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
