import { RouterProvider } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/effect-coverflow";
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './App.css';
import { routes } from './Routes/Router';
import './assets/css/style.css';
import { ProjectDataProvider } from './data/ProjectDataContext';
import { TeamDataProvider } from './data/TeamDataContext';
import { GeneralDataProvider} from "./data/GeneralDataContext";



function App() {

    return (
      <ProjectDataProvider>
        <TeamDataProvider>
            <GeneralDataProvider>

    <div>
      <RouterProvider router={routes} />
    </div>
            </GeneralDataProvider>
        </TeamDataProvider>
      </ProjectDataProvider>
  );
}

export default App;
